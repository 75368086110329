import React from 'react';
import { Link } from 'gatsby';

import LightHeader from '../components/header/light-header';
import PageTitle from '../components/page/page-title';
import PageWrapper from '../components/page/page-wrapper';
import SEO from '../components/seo';

export default function NotFoundPage() {
  return (
    <PageWrapper>
      <SEO title="Thank you for subscribing" />
      <LightHeader />
      <PageTitle smallMargin>Thank you for subscribing</PageTitle>
      <p>
        You are now confirmed. You can expect to receive emails as I create new
        content.
      </p>
      <Link to="/blog">Go to blog →</Link>
    </PageWrapper>
  );
}
